function drawObjects() {
    //canvas5
    $('.ObjData').each(function (index) {

        //<div class="Canvas_Product_height">@item.Canvas_Product_height</div>
        //<div class="Canvas_Dead_height">@item.Canvas_Dead_height</div>
        //<div class="Canvas_Safety_height">@item.Canvas_Safety_height</div>
        //<div class="Canvas_Alarm_height">@item.Canvas_Alarm_height</div>
        //<div class="Canvas_Warning_height">@item.Canvas_Warning_height</div>

        //Variables needed
        var actualvolume = $(this).children('.Canvas_Product_height').text();
        var deadvolume = $(this).children('.Canvas_Dead_height').text();
        var safevolume = $(this).children('.Canvas_Safety_height').text();
        var alarmvolume = $(this).children('.Canvas_Alarm_height').text();
        var warningvolume = $(this).children('.Canvas_Warning_height').text();
        var objectColor = $(this).children('.Object_Color').text();
        var attentionColor = $(this).children('.CSS_color_attention').text();
        var objectGroupID = $(this).children('.Object_group_ID').text();

        if ($(this).children('.gadgetGraphics').text() == 1) {
            //css procent change
            $('.cistern:eq(' + index + ')').find('.actualvolume').css("width", actualvolume + '%');
            $('.cistern:eq(' + index + ')').find('.safevolume').css("width", safevolume + '%');
            $('.cistern:eq(' + index + ')').find('.deadvolume').css("width", deadvolume + '%');
            $('.DrawStack:eq(' + index + ')').find(".warningvolume").css("left", warningvolume + "%");
            $('.DrawStack:eq(' + index + ')').find(".alarmvolume").css("left", alarmvolume + "%");
        }
        else {
            //Variables needed
            var w = jQuery(window).width();
            var h = jQuery(window).height();
            var canvasSize = w * 0.4;
            var cylinderYsize = w * 0.5;
            var cp = canvasSize / 2;
            var cs = canvasSize * 0.35;
            var xp = canvasSize * 0.2;
            var yp = canvasSize * 0.2;
            var size = canvasSize * 0.6;
            var linewidth = 2;
            var objectshape = $(this).children('.Object_Shape').text();

            //draw function
            drawcanvas(objectshape, index, canvasSize, linewidth, actualvolume, safevolume, deadvolume, cylinderYsize, w, cp, cs, xp, yp, size, alarmvolume, warningvolume, objectColor, attentionColor, objectGroupID);
            if (objectshape == 4) {
                $(".drawCanvas:eq(" + index + ")").css("height", cylinderYsize);
            }
            else {
                $(".drawCanvas:eq(" + index + ")").css("height", canvasSize);
            }
        }
    });
};
function drawcanvas(objectshape, i, c, linewidth, actualvolume, safevolume, deadvolume, y, w, cp, cs, xp, yp, size, alarmvolume, warningvolume, objectColor, attentionColor, objectGroupID) {
     //console.log('objectGroupID: ', objectGroupID);
     //console.log('actualvolume: ', actualvolume);
     //console.log('deadvolume: ', deadvolume);
     //console.log('y: ', y);
     //console.log('w: ', w);
     //console.log('cp: ', cp);
     //console.log('cs: ', cs);
     //console.log('xp: ', xp);
     //console.log('yp: ', yp);
     //console.log('size: ', size);
     //console.log('----------------------------------------');
    switch (objectshape) {
        case '1':
            //#region Square

            var canvas = document.createElement("CANVAS")
            canvas.setAttribute("class", "canvas")
            var context = canvas.getContext('2d');
            canvas.height = c;
            canvas.width = c;

            // actualvolume
            context.beginPath();
            context.rect(xp, (yp + size) - (size * (actualvolume) / 100), size, size * (actualvolume) / 100);
            context.fillStyle = objectColor;
            context.fill();
            context.closePath();

            //safevolume
            context.beginPath();
            context.rect(xp, yp, size, size * safevolume / 100);
            context.fillStyle = attentionColor;
            context.fill();
            context.closePath();

            //deadvolume
            context.beginPath();
            context.rect(xp, (yp + size) - (size * (deadvolume) / 100), size, size * deadvolume / 100);
            context.fillStyle = attentionColor;
            context.fill();
            context.closePath();

            //// middle black line
            //context.beginPath();
            //context.rect(0, ((c / 2)), c, 1);
            //context.fillStyle = 'black';
            //context.fill();
            //context.closePath();

            //border
            context.beginPath();
            context.rect(xp, yp, size, size);
            context.fillStyle = 'transparent';
            context.fill();
            context.strokeStyle = 'black'
            context.lineWidth = linewidth;
            context.stroke();
            context.closePath();

            $(".drawCanvas:eq(" + i + ")").empty().append(canvas);
            //#endregion
            break;
        case '2':
            //#region circle
            var canvas = document.createElement("CANVAS")
            canvas.width = c;
            canvas.height = c;
            canvas.setAttribute("class", "canvas")
            var context = canvas.getContext('2d');

            var Radius = cs - linewidth;
            var diameter = Radius + Radius;

            // thick border
            context.fillStyle = "white";
            context.strokeStyle = "black";
            context.lineWidth = linewidth;
            context.beginPath();
            context.arc(cp, cp, Radius, 0, 2 * Math.PI, false);
            context.closePath();
            context.stroke();
            context.fill();
            context.stroke();

            // clip
            context.beginPath();
            context.arc(cp, cp, Radius, 0, 2 * Math.PI, false);
            context.closePath()
            context.clip();

            // actualvolume
            context.beginPath();
            context.rect(0, cp + Radius - diameter * (actualvolume) / 100, c, diameter * (actualvolume) / 100);
            context.fillStyle = objectColor;
            context.fill();
            context.closePath();

            // safevolume
            context.beginPath();
            context.rect(0, cp - Radius, c, diameter * safevolume / 100);
            context.fillStyle = attentionColor;
            context.fill();
            context.closePath();

            // deadvolume
            context.beginPath();
            context.rect(0, cp + Radius - diameter * deadvolume / 100, c, diameter * deadvolume / 100);
            context.fillStyle = attentionColor;
            context.fill();
            context.closePath();

            //// middle black line
            //context.beginPath();
            //context.rect(0, ((c / 2)), c, 1);
            //context.fillStyle = 'black';
            //context.fill();
            //context.closePath();

            //border
            context.beginPath();
            context.arc(cp, cp, Radius, 0, 2 * Math.PI, false);
            context.fillStyle = 'transparent';
            context.fill();
            context.lineWidth = linewidth;
            context.strokeStyle = 'black';
            context.stroke();
            context.closePath();


            $(".drawCanvas:eq(" + i + ")").empty().append(canvas);


            //#endregion
            break;
        case '3':
            break;
        case '4':
            //#region Cylinder
            var canvas = document.createElement("CANVAS")
            canvas.width = c;
            canvas.height = y;
            canvas.setAttribute("class", "canvas")
            var context = canvas.getContext('2d');
            var topY = y * 0.2;
            var rightX = c * 0.8;
            var bottomY = y * 0.8;
            var leftX = c * 0.2;
            //satte den till 20 först. dela 20 på bredden för att få ut decimal talet.
            var curvelength = w * 0.0555555555555556;
            var ph = 0.75;
            var totalHeight = y * 0.6 + 2 * curvelength * ph;

            //clip
            context.beginPath();
            context.moveTo(leftX + linewidth, topY);
            context.lineTo(leftX + linewidth, bottomY);
            context.bezierCurveTo(leftX + linewidth, bottomY + curvelength, rightX - linewidth, bottomY + curvelength, rightX - linewidth, bottomY);
            context.lineTo(rightX - linewidth, topY);
            context.bezierCurveTo(rightX - linewidth, topY - curvelength, leftX + linewidth, topY - curvelength, leftX + linewidth, topY);
            context.lineWidth = linewidth;
            context.strokeStyle = 'black';
            context.stroke();
            context.clip();
            context.closePath();

            // actualvolume
            context.beginPath();
            context.rect(0, (bottomY + curvelength * ph) - (totalHeight + curvelength) * (actualvolume) / 100, c, (totalHeight + curvelength) * actualvolume / 100);
            context.fillStyle = objectColor;
            context.fill();
            context.closePath();

            //safevolume
            context.beginPath();
            context.rect(xp, topY - curvelength * ph, c, totalHeight * safevolume / 100);
            context.fillStyle = attentionColor;
            context.fill();
            context.closePath();

            //deadvolume
            context.beginPath();
            context.rect(0, (bottomY + curvelength * ph) - (totalHeight + curvelength) * deadvolume / 100, c, (totalHeight + curvelength) * deadvolume / 100);
            context.fillStyle = attentionColor;
            context.fill();
            context.closePath();

            //// middle black line
            //context.beginPath();
            //context.rect(0, y / 2, c, 1);
            //context.fillStyle = 'black';
            //context.fill();
            //context.closePath();

            //border
            context.beginPath();
            context.moveTo(leftX + linewidth, topY);
            context.lineTo(leftX + linewidth, bottomY);
            context.bezierCurveTo(leftX + linewidth, bottomY + curvelength, rightX - linewidth, bottomY + curvelength, rightX - linewidth, bottomY);
            context.lineTo(rightX - linewidth, topY);
            context.bezierCurveTo(rightX - linewidth, topY - curvelength, leftX + linewidth, topY - curvelength, leftX + linewidth, topY);
            context.lineWidth = linewidth;
            context.strokeStyle = 'black';
            context.stroke();
            context.closePath();

            $(".drawCanvas:eq(" + i + ")").empty().append(canvas);
            //#endregion
            break;
        case '5':
            //#region MultipleSquares
            var canvas = document.createElement("CANVAS")
            canvas.setAttribute("class", "canvas")

            var context = canvas.getContext('2d');
            canvas.height = c;
            canvas.width = c;
            context.fillStyle = "white";
            context.strokeStyle = "black";
            context.lineWidth = linewidth;
            context.beginPath();
            context.rect((xp), (yp), size, size);
            context.closePath();

            context.shadowColor = '#999';
            context.shadowBlur = 3;
            context.shadowOffsetX = -15;
            context.shadowOffsetY = -15;
            context.stroke();
            context.fill();

            context.shadowColor = 0;
            context.shadowBlur = 0;
            context.shadowOffsetX = 0;
            context.shadowOffsetY = 0;
            context.stroke();

            // actualvolume
            context.beginPath();
            context.rect(xp, (yp + size) - (size * (actualvolume) / 100), size, size * (actualvolume) / 100);
            context.fillStyle = objectColor;
            context.fill();
            context.closePath();

            //safevolume
            context.beginPath();
            context.rect(xp, yp, size, size * safevolume / 100);
            context.fillStyle = attentionColor;
            context.fill();
            context.closePath();

            //deadvolume
            context.beginPath();
            context.rect(xp, (yp + size) - (size * (deadvolume) / 100), size, size * deadvolume / 100);
            context.fillStyle = attentionColor;
            context.fill();
            context.closePath();

            //border
            context.beginPath();
            context.rect((xp), (yp), size, size);
            context.fillStyle = 'transparent';
            context.fill();
            context.strokeStyle = 'black'
            context.lineWidth = linewidth;
            context.stroke();
            context.closePath();

            $(".drawCanvas:eq(" + i + ")").empty().append(canvas);
            //#endregion
            break;
        case '6':
            //#region multipleCircles
            var canvas = document.createElement("CANVAS")
            canvas.width = c;
            canvas.height = c;
            canvas.setAttribute("class", "canvas")
            var context = canvas.getContext('2d');

            var Radius = cs - linewidth;
            var diameter = Radius + Radius;

            context.fillStyle = "white";
            context.strokeStyle = "black";
            context.lineWidth = linewidth;
            context.beginPath();
            context.arc(cp, cp, cs - linewidth, 0, 2 * Math.PI, false);
            context.closePath();

            context.shadowColor = '#999';
            context.shadowBlur = 3;
            context.shadowOffsetX = -15;
            context.shadowOffsetY = -10;
            context.stroke();
            context.fill();

            context.shadowColor = 0;
            context.shadowBlur = 0;
            context.shadowOffsetX = 0;
            context.shadowOffsetY = 0;
            context.stroke();

            //clip
            context.beginPath();
            context.arc(cp, cp, cs - linewidth, 0, 2 * Math.PI, false);
            context.closePath()
            context.clip();

            // actualvolume
            context.beginPath();
            context.rect(0, cp + Radius - diameter * (actualvolume) / 100, c, diameter * (actualvolume) / 100);
            context.fillStyle = objectColor;
            context.fill();
            context.closePath();

            // safevolume
            context.beginPath();
            context.rect(0, cp - Radius, c, diameter * safevolume / 100);
            context.fillStyle = attentionColor;
            context.fill();
            context.closePath();

            // deadvolume
            context.beginPath();
            context.rect(0, cp + Radius - diameter * deadvolume / 100, c, diameter * deadvolume / 100);
            context.fillStyle = attentionColor;
            context.fill();
            context.closePath();

            //border
            context.beginPath();
            context.arc(cp, cp, cs - linewidth, 0, 2 * Math.PI, false);
            context.fillStyle = 'transparent';
            context.fill();
            context.lineWidth = linewidth;
            context.strokeStyle = 'black';
            context.stroke();
            context.closePath();

            $(".drawCanvas:eq(" + i + ")").empty().append(canvas);
            //#endregion
            break;
        default:
    }
}
//If searchbutton is pressed
function toggleTextButton() {
    toggleText('button');
    $('#searchBox').keyup();
}
//Delay funcction
var Timeout = {
    _timeouts: {},
    set: function (name, func, time) {
        this.clear(name);
        this._timeouts[name] = { pending: true, func: func };
        var tobj = this._timeouts[name];
        tobj.timeout = setTimeout(function () {
            /* setTimeout normally passes an accuracy report on some browsers, this just forwards that. */
            tobj.func.call(arguments);
            tobj.pending = false;
        }, time);
    },
    hasRun: function (name) {
        if (this._timeouts[name]) {
            return !this._timeouts[name].pending;
        }
        return -1; /* Whut? */
    },
    runNow: function (name) {
        if (this._timeouts[name] && this.hasRun(name) === false) {
            this._timeouts[name].func(-1); /* fake time. *shrug* */
            this.clear(name);
        }
    },
    clear: function (name) {
        if (this._timeouts[name] && this._timeouts[name].pending) {
            clearTimeout(this._timeouts[name].timeout);
            this._timeouts[name].pending = false;
        }
    }
};
// Delay of key in inbox
// 20141027 JS kod Andreas
function throttle(fn, delay) {
    var timeout = 0;
    var throttleTimer = 200;

    return function () {
        var context = this;
        var args = arguments;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            fn.apply(context, arguments);
        }, delay || throttleTimer);
    };
}
// SiteInfo spinner based on spin.js replaces ajax-loader.gif
// 20141030 Dag
function siteinfoSpinner(siteinfoSpinnerID) {
    var loaders = [{
        width: 100,
        height: 100,
        stepsPerFrame: 1,
        trailLength: 1,
        pointDistance: 0.025,
        strokeColor: '#50A83E',
        fps: 20,

        setup: function () {
            this._.lineWidth = 3;
        },
        step: function (point, index) {
            var cx = this.padding + 50,
				cy = this.padding + 50,
				_ = this._,
				angle = (Math.PI / 180) * (point.progress * 360);
            this._.globalAlpha = Math.max(0 - 0.3, this.alpha);

            _.beginPath();
            _.moveTo(point.x, point.y);
            _.lineTo((Math.cos(angle) * 35) + cx, (Math.sin(angle) * 35) + cy);
            _.closePath();
            _.stroke();

            _.beginPath();
            _.moveTo((Math.cos(angle - 130) * 20) + cx, (Math.sin(angle - 130) * 20) + cy);
            _.lineTo((Math.cos(angle - 130) * 15) + cx, (Math.sin(angle - 130) * 15) + cy);
            _.closePath();
            _.stroke();

        },
        path: [
			['arc', 50, 50, 40, 0, 360]
        ]
    }, {
        width: 100,
        height: 100,

        stepsPerFrame: 1,
        trailLength: 1,
        pointDistance: 0.04,
        strokeColor: '#50A83E',
        fps: 10,

        setup: function () {
            this._.lineWidth = 5;
        },
        step: function (point, index) {
            var cx = this.padding + 50,
				cy = this.padding + 50,
				_ = this._,
				angle = (Math.PI / 180) * (point.progress * 360);
            this._.globalAlpha = Math.max(0.2, this.alpha);

            _.beginPath();
            _.moveTo((Math.cos(-angle - 130) * 32) + cx, (Math.sin(-angle - 130) * 32) + cy);
            _.lineTo((Math.cos(-angle - 130) * 23) + cx, (Math.sin(-angle - 130) * 23) + cy);
            _.closePath();
            _.stroke();

        },
        path: [
			['arc', 50, 50, 40, 0, 360]
        ]
    }];

    var d, a, container = document.getElementById(siteinfoSpinnerID);
    d = document.createElement('div');
    d.className = 'siteinfo-spinner-inner';

    for (var i = -1, l = loaders.length; ++i < l;) {
        a = new Sonic(loaders[i]);
        a.canvas.style.marginTop = (100 - a.height) / 2 + 'px';
        a.canvas.style.marginLeft = (100 - a.height) / 2 + 'px';
        a.canvas.style.top = '0px';
        a.canvas.style.left = '0px';
        a.canvas.style.position = 'absolute';
        a.play();
        d.appendChild(a.canvas);
    }
    container.appendChild(d);
}

function setAlarm() {
    $.ajax({
        type: "POST",
        url: "/Alarm/SetAlarm",
        success: function (data) {
            if (!data.success) {
                console.log('Success');
            }
            else {
                console.log('Failed');
            }
        }
    });
}

// selectes correct alarm icon.
// gets correct text on alarm view.
function AlarmHandler(data, elem) {
    for (var i = 0; i < data.length; i++) {
        var item = data[i];
        var iconElem = '';
        if (elem == undefined) {
            iconElem = $('#aog' + item.db_Object_group_ID + ' span');
        }
        else {
            iconElem = elem;
        }
        notification_status = item.C_Notification_Unexpected_level_change_status;

        var timeElem = $('#alarmTimeDiv');
        var NotificationText = $('#Notification_Unknown_Level_Change_Status_' + notification_status).html();
        if (timeElem != undefined) {
            if (notification_status != 2) {
                timeElem.html('');
                $('#alarmTimeDivMinutes').html('');
            }
            else if (item.C_Notification_Unexpected_level_change_activation_timer <= 0) {
                //timeElem.html($('#Notification_Unknown_Level_Change_Status_Waiting_For_New_Value').html());
                NotificationText = $('#Notification_Unknown_Level_Change_Status_Waiting_For_New_Value').html();
                timeElem.html('');
                $('#alarmTimeDivMinutes').html('');
                timeElem.css('font-size', 24);
            }
            else {
                timeElem.html(item.C_Notification_Unexpected_level_change_activation_timer);
                var timeElemMinutes = $('#C_Notification_Unknown_Level_Change_Status_Minutes').html();
                $('#alarmTimeDivMinutes').html(timeElemMinutes);
                timeElem.css('font-size', 80);
            }
            $('#Notification_Unknown_Level_Change_Status').html(NotificationText);
        }

        // -1 = unexpected level change unqualified
        //  0 = off, no schedule
        //  1 = off, scheduled
        //  2 = off, activation pending
        //  3 = on, activated
        //  4 = on, unexpected level change notification
        if (notification_status === 1) {
            // //1 = off, scheduled
            iconElem.css('color', 'green');
            iconElem.addClass('glyphicons-unlock');
            iconElem.removeClass('glyphicons-rotation-lock siteinfo-symbol-blink glyphicons-lock glyphicons-warning-sign');
        }
        else if (notification_status === 2) {
            // //2 = off, theft activation pending
            // // make it blink
            iconElem.css('color', 'red');
            iconElem.addClass('glyphicons-rotation-lock siteinfo-symbol-blink');
            iconElem.removeClass('glyphicons-unlock glyphicons-lock glyphicons-warning-sign');
        }
        else if (notification_status === 3) {
            // //3 = on, activated
            iconElem.css('color', 'red');
            iconElem.addClass('glyphicons-lock');
            iconElem.removeClass('glyphicons-unlock glyphicons-rotation-lock siteinfo-symbol-blink glyphicons-warning-sign');
        }
        else if (notification_status === 4) {
            // //3 = on, activated
            iconElem.css('color', 'red');
            iconElem.addClass('glyphicons-warning-sign siteinfo-symbol-blink');
            iconElem.removeClass('glyphicons-unlock glyphicons-rotation-lock glyphicons-lock');
        }
        else {
            // else = dont show
            iconElem.removeClass('glyphicons-unlock glyphicons-rotation-lock siteinfo-symbol-blink glyphicons-lock');
        }
    }
}