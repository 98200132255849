// IE hack trim
if (typeof String.prototype.trim !== 'function') {
    String.prototype.trim = function () {
        return this.replace(/^\s+|\s+$/g, '');
    };
}

siteinfo = siteinfo({});

function siteinfo(settings) {
    settings = $.extend({
        ajaxretrycount: 5
    }, settings || {});

    return {
        ajaxifyForm: ajaxifyForm,
        ajaxifyFormPopDialog: ajaxifyFormPopDialog,
        showObjectgroupsOnMap: showObjectgroupsOnMap, // Selected object group in listsites, for Google map
        siteObjectgroupsInventory: siteObjectgroupsInventory, // Inventory
        calculateListSitesSum: calculateListSitesSum, // Calculate sum in listsites
        throttle: throttle, // Delay of keyup in inboxes for IE9
        grafVerticalPlotBandGeneration: grafVerticalPlotBandGeneration, //vertical plotbands in graf
        grafWeekNumber: grafWeekNumber, //weekNumber UTC
        grafButton: grafButton, // language of graf button
        grafButtonTheme: grafButtonTheme, // theme of graf button
        grafLang: grafLang, // user language text of graf
        grafConsumption: grafConsumption, // make estimated comsumption serie
        graf: graf // Main graf function
    };

    // set 205 add Location header and redir from there
    function ajaxifyForm(submitbutton) {
        function _ajaxifyClickFunction() {
            var $p = $(this).closest("form");
            var data = $p.serialize();
            var method = $p.attr('method');
            function _submit(c) {
                $.ajax({
                    url: $p[0].action,
                    type: method,
                    data: data,
                    async: true,
                    complete: function (xhr, m) {
                        // Test if dialog data saved in dB - 20131007 DAG
                        if (xhr.responseText === 'Dialog_ok') {
                            $("#popdialog").remove();
                        }
                        else if (xhr.responseText === 'Parent_view_reload') {
                            window.location.reload(true);
                        }
                        else if (xhr.responseText === 'Popup_validation_error') {
                            alert("Popup_validation_error");
                            //$("form").removeData("validator");
                            //$("form").removeData("unobtrusiveValidation");
                            //$.validator.unobtrusive.parse("form");
                        }
                        //POST REDIRECT GET PATTERN:
                        else if (xhr.status !== 200 && xhr.status !== 205) {
                            window.location.reload(true);
                        }
                        else if (xhr.status === 205) {
                            if (window.location === xhr.getResponseHeader('Location') || '/') {
                                window.location.reload(true);
                            }
                            else if (xhr.getResponseHeader('Location')) {
                                window.location = xhr.getResponseHeader('Location');
                            }
                            //return window.location = xhr.getResponseHeader('Location');
                            else {
                                window.location.reload(true);
                            }
                        }
                    },
                    success: function (d, t, x) {                           //
                        if (x.status === 200 && d.charAt(0) === '<') {
                            var $d = $(d);
                            var s = $d.find("form input:submit");
                            s.click(_ajaxifyClickFunction);
                            $p.replaceWith($d.find("form"));
                        }
                    },
                    error: function (x, e, m) {
                        if (c > 1) {
                            _submit(c - 1);
                        }
                    }
                });
                return false;
            }
            $p.submit(_submit(3));
            return true;
        }
        $(submitbutton).click(_ajaxifyClickFunction);
    }

    // 20141012 Popdialog
    function ajaxifyFormPopDialog(submitbutton) {
        function _ajaxifyClickFunction() {
            var $p = $(this).closest("form");
            var data = $p.serialize();
            var method = $p.attr('method');
            // script befor server post
            $("#outerpopdialogSpinnerID").show(); // show spinner

            $.ajax({
                url: $p[0].action,
                type: method,
                data: data,
                async: true,
                cache: false,
                success: function (data, t, x) {
                    if (data.Result === 'Parent_view_reload') {
                        // if link exists in href, redirect to page
                        if (data.id === "") {
                            if (data.href === "") {
                                window.location.reload(true);
                            }
                            window.location.href = data.href;
                        }
                        else {
                            // load html to parent data.id, partialView in data.href
                            //console.log(data.href, data.id);
                            $("#" + data.id).parent().load(data.href);
                            $("#popdialog").remove();
                        }
                    }
                    else if (data.Result === 'Validation_error') {
                        $("#outerpopdialogSpinnerID").hide(); // hide spinner
                        var validationContainer = $('#popdialogError');
                        var validationUl = $('#popdialogError_ul');
                        // remove previous validation
                        validationUl.empty();
                        $('.input-validation-error').removeClass('input-validation-error');
                        // add validation text
                        data.ValidationText.forEach(function (vt) {
                            validationUl.append('<li>' + vt + '</li>');
                        });
                        // add validation class
                        data.validationField.forEach(function (vf) {
                            $('input[name=\'' + vf + '\']').addClass('input-validation-error');
                        });
                        validationContainer.show();
                        // activate ajax on save button
                        var s = $p.find("input:submit");
                        s.click(_ajaxifyClickFunction);
                        return true;
                    }
                },
                error: function (xhr, e, m) {
                    // #### Server error, try again
                    $("#outerpopdialogSpinnerID").hide(); // hide spinner
                    var validationContainer = $('#popdialogError');
                    var validationUl = $('#popdialogError_ul');
                    // remove previous validation
                    validationUl.empty();
                    $('.input-validation-error').removeClass('input-validation-error');
                    // add validation text
                    validationUl.append('<li>' + 'Unhandled exception: ' + e + ' - ' + m + '<br />Try again!</li>');
                    validationContainer.show();
                    // activate ajax on save button
                    var s = $p.find("input:submit");
                    s.click(_ajaxifyClickFunction);
                    return true;
                }
            });
            return false;
        }
        $(submitbutton).click(_ajaxifyClickFunction);
        //siteinfoSpinner("innerpopdialogSpinnerID"); // create spinner	, is created in dialog.js
    }

    // Selected object group in listsites, for Google map
    // 20141103 Dag
    function showObjectgroupsOnMap(mapType, siteCounter_url) {
        var listOfObjectgroups = "";
        var ogCounter = 0;
        var ogId = '';
        $('#sitestable tbody tr:visible').each(function () {
            if (mapType === 'SiteDetails') {
                //var i = currentGrafId.split("/");
                ogId = this.id.split("/")[1]; // format siteId / objGrpId / objId
            }
            else {
                ogId = this.id;
            }

            listOfObjectgroups += ogId + ',';
            ogCounter++;
        });
        // if last char is , remove
        if (listOfObjectgroups.slice(-1) === ',') {
            listOfObjectgroups = listOfObjectgroups.slice(0, -1);
        }
        // if direction and more than 23 position alert
        if (ogCounter > 23 && mapType === 'Directions') {
            siteCounter_extra_test(listOfObjectgroups, siteCounter_url);
            return true;
        }
        $('#listOfObjectgroups').val(listOfObjectgroups);
        $('#mapType').val(mapType);
        $("#goToMapbutton").click();
        $("#goToMapbutton").off(event);
    }

    function siteCounter_extra_test(listOfObjectgroups, siteCounter_url) {
        // Extra check wiith AJAX if to many positions
        ajaxflag = false;
        var url = siteCounter_url + '?_=' + (new Date()).getTime(); // Make no caching in IE
        $.ajax({
            type: 'post',
            url: url,
            data: {
                listOfObjectgroups: listOfObjectgroups
            },
            dataType: 'text',
            success: function (data) {
                if (parseInt(data) > 23) {
                    jAlert($('#mapAlertMessage').val() + ' (' + data + ')', 'Title');
                    //alert($('#mapAlertMessage').val() + ' (' + data + ')');
                }
                else {
                    $('#listOfObjectgroups').val(listOfObjectgroups);
                    $('#mapType').val('Directions');
                    $("#goToMapbutton").click();
                    $("#goToMapbutton").off(event);
                }
            },
            error: function () {
                alert('Unknown server error');
            }
        });
    }

    // Site object group history in listsites
    // 20150331 Dag
    function siteObjectgroupsInventory() {
        var listOfObjectgroups = "";
        var ogCounter = 0;
        $('#sitestable tbody tr:visible').each(function () {
            listOfObjectgroups += this.id + ',';
            ogCounter++;
        });
        // if last char is , remove
        if (listOfObjectgroups.slice(-1) === ',') {
            listOfObjectgroups = listOfObjectgroups.slice(0, -1);
        }
        // if objectgroups selected, nothing
        if (ogCounter === 0) {
            alert("Inget urval!");
            return true;
        }
        $('.listOfObjectgroups').val(listOfObjectgroups);
        $("#inventorybutton").click();
        $("#inventorybutton").off(event);
    }

    // Calculate values put in tfoot of tableId
    // 20140921 JS kod från Andreas Hultgren 
    // 20140926 Added clear DH
    function calculateListSitesSum(type) {
        var calculateValuesId = '#sitestable';
        // List of column names
        var ids = [
            '#db_Object_group_Actual_Value',
            '#db_Object_group_Current_Available_value',
            '#db_Object_group_Current_Stock_value',
            '#db_Object_group_Current_Ullage_value'
        ];
        if (type === 'sum') {
            ids.forEach(calculateSum);
        }
        else {
            ids.forEach(clearSum);
        }
    }

    function calculateSum(selector) {
        var columnIndex = $(selector).index();
        if (columnIndex >= 0) {
            var sum = 0;
            $(calculateValuesId + ' tbody tr:visible').each(function () {
                var value = parseInt($(this).find('td').eq(columnIndex).text());
                if (value > 0) {
                    sum += value;
                }
            });
            $(calculateValuesId).find('tfoot tr:first th').eq(columnIndex).text(sum);
        }
    }

    function clearSum(selector) {
        var columnIndex = $(selector).index();
        if (columnIndex >= 0) {
            $(calculateValuesId).find('tfoot tr:first th').eq(columnIndex).text(' ');
        }
    }

    // Delay of key in inbox
    // 20141027 JS kod Andreas
    function throttle(fn, delay) {
        var timeout = 0;
        var throttleTimer = 200;
        if (typeof SIthrottleTimeout !== 'undefined') {
            throttleTimer = SIthrottleTimeout;
        }

        return function () {
            var context = this;
            var args = arguments;

            clearTimeout(timeout);

            timeout = setTimeout(function () {
                fn.apply(context, arguments);
            }, delay || throttleTimer);
        };
    }

    // HIGHCHARTS functions
    // Vertical plotband generation for weeks in graf
    // 20141123 JS kod från Andreas Hultgren 
    function grafVerticalPlotBandGeneration(from, to, color) {
        var fromDate = new Date(from);
        var toDate = new Date(to);
        var fromDay, weekStart, weekEnd;
        var result = [];

        // Reset start to beginning of day
        fromDate.setHours(0);
        fromDate.setMinutes(0);
        fromDate.setHours(0);
        fromDate.setMilliseconds(0);

        // Move `from` to the closest monday in the future
        fromDay = fromDate.getDay();

        if (fromDay !== 1) {
            if (fromDay === 0) {
                fromDay = 7;
            }

            fromDate.setDate(fromDate.getDate() + (8 - fromDay));
        }

        while (fromDate < toDate) {
            weekStart = fromDate.getTime() - (fromDate.getTimezoneOffset() * 60 * 1000).valueOf(); // Compensate Sweden
            fromDate.setDate(fromDate.getDate() + 7);

            weekEnd = fromDate.getTime() - (fromDate.getTimezoneOffset() * 60 * 1000).valueOf(); // Compensate Sweden
            fromDate.setDate(fromDate.getDate() + 7);

            result.push({
                from: weekStart,
                to: weekEnd,
                color: color
            });
        }

        return result;
    }

    // get weeknumber from UTC timestamp
    function grafWeekNumber(timestamp) {
        var date = new Date(timestamp),
            day = date.getUTCDay() === 0 ? 7 : date.getUTCDay(),
            dayNumber;
        date.setDate(date.getUTCDate() + 4 - day);
        dayNumber = Math.floor((date.getTime() - new Date(date.getUTCFullYear(), 0, 1, -6)) / 86400000);
        return 1 + Math.floor(dayNumber / 7);
    }

    // Make graf buttons
    function grafButton(button) {
        return [{
            type: 'month',
            count: 1,
            text: button.month
        }, {
            type: 'month',
            count: 3,
            text: button.quarter
        }, {
            type: 'year',
            count: 1,
            text: button.year
        }, {
            type: 'all',
            text: button.all
        }];
    }

    // Make	graf button theme, NOT USED ??
    function grafButtonTheme() {
        return {
            fill: 'none',
            stroke: 'none',
            'stroke-width': 0,
            r: 4,
            style: {
                color: '#777'
            },
            states: {
                hover: {
                },
                select: {
                    fill: '#777',
                    style: {
                        color: '#fff'
                    }
                }
            }
        };
    }

    // Make graf texts for user
    function grafLang(lang) {
        return {
            decimalPoint: lang.decimalPoint,
            months: JSON.parse(lang.months),
            noData: lang.noData,
            numericSymbols: JSON.parse(lang.numericSymbols),
            resetZoom: lang.resetZoom,
            resetZoomTitle: lang.resetZoomTitle,
            shortMonths: JSON.parse(lang.shortMonths),
            thousandsSep: lang.thousandsSep,
            contextButtonTitle: lang.contextButtonTitle,
            downloadJPEG: lang.downloadJPEG,
            downloadPDF: lang.downloadPDF,
            downloadPNG: lang.downloadPNG,
            downloadSVG: lang.downloadSVG,
            loading: lang.loading,
            printChart: lang.printChart,
            rangeSelectorFrom: lang.rangeSelectorFrom,
            rangeSelectorTo: lang.rangeSelectorTo,
            rangeSelectorZoom: lang.rangeSelectorZoom,
            weekdays: JSON.parse(lang.weekdays)
        };
    }

    // Make estimated consumption serie
    function grafConsumption(consumption) {
        var data = [],
            from = consumption.From,
            to = consumption.To,
            change = consumption.Value / -24,
            value = consumption.Current_value,
            min = consumption.Min,
            max = consumption.Max;
        data.push([from, value]);
        for (i = from; i <= to; i += 3600000, value += change) {
            if (value > max || value < min) {
                break;
            }
            data.push([i, value]);
        }
        return data;
    }

    // Main graf function
    function graf(currentGrafId) {
        var i = currentGrafId.split("/"); // format siteId / objType / objId

        $.getJSON('/Graf/Graf_param_json/?siteId=' + i[0] + '&objectType=' + i[1] + '&objectId=' + i[2],
            function (data) {

                // Set the datepicker's date format
                //$.datepicker.setDefaults($.datepicker.regional.sv);
                //$.datepicker.setDefaults({
                //	yearRange: "-3:+0",
                //	autoOpen: false,
                //	dateFormat: "yy-mm-dd",
                //	showStatus: true,
                //	highlightweek: true,
                //	firstDay: 1,
                //	onSelect: function () {
                //		this.onchange();
                //		this.onblur();
                //	}
                //});

                Highcharts.dateFormats = {
                    W: function (timestamp) { return siteinfo.grafWeekNumber(timestamp); } // Add custom date formats for Week number
                };

                var grafParam = data;
                var showSecondaryGraf = grafParam.showSecondaryGraf;
                var objType = grafParam.ObjType;
                var week_Short_name = grafParam.userTexts.C_Week_Short_name;
                var seriesOptions = [];
                var seriesCounter = 0;
                var names = [grafParam.userTexts.db_Object_group_Name + ':' +
                    grafParam.userTexts.Object_group_Name + ' - ' +
                    grafParam.userTexts.db_Product_Name];
                // create the chart when all lines are loaded
                var createChart = function () {
                    // MAKE PLOTLINES IF ONLY ONE LINE
                    var plotLines = [];
                    // max value
                    plotLines.push({
                        zIndex: 3,
                        id: 'max',
                        value: grafParam.Max,
                        color: '#707070',
                        width: 2
                    });
                    plotLines.push({
                        zIndex: 3,
                        id: 'min',
                        value: grafParam.Min,
                        color: '#707070',
                        width: 2
                    });
                    // MAKE ALARM LINES IF object type is group (A)
                    if (objType === 'A') {
                        if (grafParam.Notification_alarm_active) {
                            plotLines.push({
                                zIndex: 3,
                                id: 'notification_alarm_value',
                                value: grafParam.Notification_alarm_value,
                                color: 'rgba(253, 0, 0, 1.0)',
                                width: 2,
                                label: {
                                    text: grafParam.userTexts.C_Graf_NotificationAlarm_Header
                                }
                            });
                        }
                        if (grafParam.Notification_warning_active) {
                            plotLines.push({
                                zIndex: 3,
                                id: 'notification_warning_value',
                                value: grafParam.Notification_warning_value,
                                color: 'rgba(254, 136, 37, 1.0)',
                                width: 2,
                                label: {
                                    text: grafParam.userTexts.C_Graf_NotificationWarning_Header
                                }
                            });
                        }
                    }

                    // MAKE PLOTBANDS IF ONLY ONE LINE
                    var plotBands = [];
                    if (grafParam.Safety_Value > 0) {
                        plotBands.push({
                            id: 'safetyvolume',
                            from: grafParam.Max - grafParam.Safety_Value,
                            to: grafParam.Max,
                            color: 'rgba(254, 204, 168, 1.0)',
                            label: {
                                text: grafParam.userTexts.C_Graf_SafetyVolume_Header,
                                verticalAlign: 'bottom',
                                align: 'right',
                                x: 0,
                                y: 10
                            }
                        });
                    }
                    if (grafParam.Dead_Value > 0) {
                        plotBands.push({
                            id: 'deadvolume',
                            from: grafParam.Min,
                            to: grafParam.Dead_Value,
                            color: 'rgba(254, 204, 168, 1.0)',
                            label: {
                                text: grafParam.userTexts.C_Graf_DeadVolume_Header,
                                verticalAlign: 'top',
                                align: 'right',
                                x: 0,
                                y: -2
                            }
                        });
                    }

                    // STATISTIC FUNCTION
                    //grafStatistics = function (min, max, ObjType, ObjID, db_Unit_Name) {
                    //	$("#statisticsdiv").css('display', '').hide();
                    //	$("#statisticsdiv").load('/Site/_ObjectGroupStatistics', {
                    //		ObjType: ObjType,
                    //		ObjID: ObjID,
                    //		min: parseInt(min),
                    //		max: parseInt(max),
                    //		db_Unit_Name: db_Unit_Name,
                    //		consumptionStatus: grafParam.consumption.Active,
                    //		consumptionValue: grafParam.consumption.Value
                    //	}, function (responseText, statusText, xhr) {
                    //		$("#statisticsdiv").css('display', '').show();
                    //		if (responseText.length === 0) {
                    //			$("#statisticsdiv_outer").hide();
                    //		}
                    //		else {
                    //			$('#statisticsdiv_outer').css('display', '').show();
                    //		}
                    //	});
                    //};


                    // CONFIGURATE CUSTOM CONTEXT MENU
                    //var exportingMenu = Highcharts.getOptions().exporting.buttons.contextButton.menuItems;
                    //exportingMenu.splice(5, 1); // Delete SVG

                    // MAKE CONSUMPTION LINE IF PRESENT AND object type is group (A)
                    if (grafParam.consumption.Active === true && objType === 'A') {
                        seriesOptions[seriesOptions.length] = {
                            name: grafParam.consumption.db_Object_Group_Consumption_quantity, // Add name
                            data: siteinfo.grafConsumption(grafParam.consumption), // Add serie
                            dashStyle: 'ShortDashDot',
                            color: grafParam.borderColor,
                            lineWidth: 3,
                            marker: {
                                enabled: false
                            }
                        };
                    }

                    excelExport = function () {
                        var chart = $('#realgraf').highcharts();
                        var exportUrl = '/Site/_ObjectHistoryExport?id=' + grafParam.ObjType + grafParam.ObjID +
                            '&from=' + encodeURI(Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', chart.get('x-axis').min)) +
                            '&to=' + encodeURI(Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', chart.get('x-axis').max)) +
                            '&name=' + grafParam.userTexts.db_Object_group_Name + ':' +
                            grafParam.userTexts.Object_group_Name + ' - ' +
                            grafParam.userTexts.db_Product_Name + '&defunits=' + grafParam.userTexts.db_Unit_Name;
                        window.location.href = exportUrl;
                    };
                    // Put in menu
                    //exportingMenu.push({
                    //    separator: true
                    //});
                    //exportingMenu.push({
                    //    text: grafParam.userTexts.C_Graf_ExcelExport_menu,
                    //    onclick: excelExport
                    //});

                    // delete possible only for super admin and one line and group
                    //if (grafParam._isAdmin && objType == 'A') {
                    //    deleteObjectData = function () {
                    //        var chart = $('#realgraf').highcharts();
                    //        var deleteUrl = '/Site/_ObjectGroupHistoryDelete?objectgroupId=' + grafParam.ObjType + grafParam.ObjID +
                    //			  '&from=' + encodeURI(Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', chart.get('x-axis').min)) +
                    //			  '&to=' + encodeURI(Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', chart.get('x-axis').max));
                    //        _popdialog(deleteUrl);
                    //    };
                    //    // Put in menu
                    //    exportingMenu.push({
                    //        text: grafParam.userTexts.C_Graf_Delete_Object_data_menu,
                    //        onclick: deleteObjectData
                    //    });
                    //}

                    Highcharts.setOptions({
                        lang: siteinfo.grafLang(grafParam.languageTexts)
                        //,colors: ['#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4']
                    });

                    $('#realgraf').highcharts('StockChart', {
                        chart: {
                            reFlow: true,
                            //borderColor: grafParam.borderColor,
                            //borderWidth: 2,
                            //borderRadius: 6,
                            renderTo: 'realgraf', // id of object
                            //zoomType: 'x', // only zoom x-axis
                            events: {
                                load: function () {
                                    if (objType === 'A') {
                                        var chart = $('#realgraf').highcharts();
                                        //grafStatistics(chart.get('x-axis').min, chart.get('x-axis').max, grafParam.ObjType, grafParam.ObjID, grafParam.userTexts.db_Unit_Name);
                                    }
                                },
                                redraw: siteinfo.throttle(function () {
                                    if (objType === 'A') {
                                        var chart = $('#realgraf').highcharts();
                                        //grafStatistics(chart.get('x-axis').min, chart.get('x-axis').max, grafParam.ObjType, grafParam.ObjID, grafParam.userTexts.db_Unit_Name);
                                    }
                                })
                            }
                        },
                        rangeSelector: {
                            enabled: false
                        },
                        xAxis: {
                            id: 'x-axis',
                            ordinal: false,
                            maxZoom: 1 * 3600000, // one hour,  24 * == one day
                            dateTimeLabelFormats: {
                                second: '%H:%M',
                                minute: '%H:%M',
                                hour: '%a %H:%M<br/>(' + week_Short_name + '%W)',
                                day: '%d %b<br/>%a (' + week_Short_name + '%W)',
                                //day: '%e %b<br/>(' + week_Short_name + '%W)',
                                week: '%b %Y<br/>(' + week_Short_name + '%W)',
                                month: '%b %Y<br/>(' + week_Short_name + '%W)',
                                year: '%Y'
                            },
                            type: 'datetime',
                            labels: {
                                align: 'center',
                                rotation: 0
                            },
                            plotBands: siteinfo.grafVerticalPlotBandGeneration(grafParam.From, grafParam.To, grafParam.plotBandColor),
                            useHTML: true
                        },
                        yAxis: [{
                            opposite: false,
                            min: grafParam.Min,
                            max: grafParam.Max,
                            labels: {
                                x: -4,
                                format: '{value} ' + grafParam.userTexts.db_Unit_Short_name
                            },
                            title: {
                                text: grafParam.userTexts.db_Object_group_Current_Stock_value,
                                style: {
                                    color: Highcharts.getOptions().colors[0]
                                }
                            },
                            plotLines: plotLines,
                            plotBands: plotBands,
                            showEmpty: false
                        }, // Second Y-axis for percentage
                        {
                            opposite: true,
                            min: 0,
                            ceiling: 100,
                            minRange: 100,
                            labels: {
                                x: 0,
                                format: '{value}%'
                            },
                            title: {
                                text: grafParam.userTexts.db_Object_group_Current_Stock_value + ' (%)',
                                style: {
                                    color: Highcharts.getOptions().colors[1]
                                }
                            },
                            showEmpty: false
                        }, // Third Y-axis for flow
                        {
                            opposite: true,
                            min: 0,
                            //ceiling: 100,
                            //minRange: 100,
                            labels: {
                                x: 0,
                                format: '{value}liter'
                            },
                            title: {
                                text: grafParam.userTexts.db_Object_group_Current_Stock_value + ' (liter)',
                                style: {
                                    color: Highcharts.getOptions().colors[2]
                                }
                            },
                            showEmpty: false
                        }],
                        tooltip: {
                            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y} </b><br/>',
                            changeDecimals: 0,
                            valueDecimals: 0
                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: true,
                                    radius: 2
                                }
                            }
                        },
                        style: {
                            borderWidth: 50
                        },
                        credits: {
                            enabled: false
                        },
                        legend: {
                            enabled: false,
                            y: -60,
                            floating: true,
                            itemDistance: 30,
                            symbolWidth: 80
                        },
                        exporting: {
                            enabled: false
                        },
                        navigator: {
                            enabled: false
                        },
                        scrollbar: {
                            enabled: false
                        },
                        series: seriesOptions
                    }, function (chart) {
                        // Set extremes, user fefault daterange
                        chart.xAxis[0].setExtremes(grafParam.ZoomFrom, grafParam.ZoomTo);
                        // apply the date pickers
                        //setTimeout(function () {
                        //    $('input.highcharts-range-selector', $(chart.container).parent()).datepicker({
                        //        beforeShow: function (i, obj) {
                        //            $widget = obj.dpDiv;
                        //            window.$uiDatepickerDiv = $widget;
                        //            if ($widget.data("top")) {
                        //                setTimeout(function () {
                        //                    $uiDatepickerDiv.css("top", $uiDatepickerDiv.data("top"));
                        //                }, 50);
                        //            }
                        //        },
                        //        onClose: function (i, obj) {
                        //            $widget = obj.dpDiv;
                        //            $widget.data("top", $widget.position().top);
                        //        }
                        //    });
                        //}, 0);
                    });
                };

                $.each(names, function (i, name) {
                    var iterate = 1;
                    if (showSecondaryGraf === "Flow" || showSecondaryGraf === "Percentage") {
                        // Show percentage or flow
                        iterate = 2;
                    }
                    /*jshint -W083 */ // function within a loop
                    while (iterate > 0) {
                        $.getJSON('/Graf/Graf_data_json', {
                            ObjID: grafParam.ObjID,
                            ObjType: grafParam.ObjType,
                            From: grafParam.From,
                            To: grafParam.To,
                            ShowSecondaryGraf: iterate === 2 ? "" : showSecondaryGraf
                        }, function (data) {
                            var secondary = false;
                            if (showSecondaryGraf === "Flow" || showSecondaryGraf === "Percentage") {
                                // Show percentage
                                iterate = 2;
                                secondary = true;
                            }
                            var valueSuffix = grafParam.userTexts.db_Unit_Name;
                            var yaxis = 0;
                            if (data.showSecondaryGraf === 'Percentage') { valueSuffix = '%'; yaxis = 1; }
                            if (data.showSecondaryGraf === 'Flow') { valueSuffix = 'liter'; yaxis = 2; }
                            var postfix = '';
                            if (data.showSecondaryGraf === 'Percentage') { postfix = ' (%)'; }
                            if (data.showSecondaryGraf === 'Flow') { postfix = ' (flödesvolym)'; }
                            seriesOptions[seriesCounter++] = {
                                name: name + postfix,
                                yAxis: yaxis,
                                tooltip: {
                                    valueSuffix: valueSuffix
                                },
                                data: data.chartData
                            };
                            if (secondary && seriesCounter === names.length * 2 || !secondary && seriesCounter === names.length) {
                                $("#realgraf").show();
                                $("#siteinfo-spinner-outer").hide();
                                createChart(); // Create 
                            }
                        });
                        iterate--;
                    }
                });
            });
    }
}

